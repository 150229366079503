import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { api } from '~/api';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';
import { placeToAddress } from '~/features/addresses/address';
import { useGoogleMaps } from '~/hooks/use-google-maps';

export const useIpZipCode = ({ enabled = true }) => {
  const [google] = useGoogleMaps();
  const [autocomplete, setAutocomplete] = useState();
  const [autocompleteToken, setAutocompleteToken] = useState();
  const [places, setPlaces] = useState();
  const [address, setAddress] = useState(null);
  const [addressText, setAddressText] = useState(null);

  useEffect(() => {
    if (enabled && google?.maps?.places) {
      setAutocomplete(new google.maps.places.AutocompleteService());
      setAutocompleteToken(new google.maps.places.AutocompleteSessionToken());
      setPlaces(
        new google.maps.places.PlacesService(document.createElement('div'))
      );
    }
  }, [enabled, google]);

  const { data, ...rest } = useQuery(
    'ip-geolocation',
    () => api.geolocation.find(),
    { enabled }
  );
  const zipCode = data?.postalCode;

  useEffect(() => {
    if (!enabled || !zipCode || !autocomplete) {
      return;
    }

    autocomplete.getPlacePredictions(
      {
        input: zipCode,
        sessionToken: autocompleteToken,
        types: ['postal_code'],
        componentRestrictions: {
          country: 'us',
        },
      },
      (predictions) => {
        const prediction = predictions?.[0];
        setAddressText(prediction?.description);

        const placeId = prediction?.place_id;

        // If we don't have a place_id, we can't really do anything, so let's return and not autofill anything
        if (!placeId) {
          return;
        }

        places.getDetails(
          {
            placeId,
            sessionToken: autocompleteToken,
            fields: ['place_id', 'address_components', 'geometry'],
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const address = placeToAddress(place, AutocompleteTypes.ZIP_CODE);
              setAddress(address);
            }
          }
        );
      }
    );
  }, [autocomplete, autocompleteToken, enabled, google, places, zipCode]);

  return {
    address,
    addressText,
    ...rest,
  };
};
